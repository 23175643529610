<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>

      <a-form-model-item label="英文标题" prop="titleEn" >
        <a-input v-model="form.titleEn" placeholder="请输入英文标题" />
      </a-form-model-item>


      <a-form-model-item label="内容" prop="content" >
        <editor editorValue="contents" v-model="form.content" />
      </a-form-model-item>


      <a-form-model-item label="英文内容" prop="contentEn" >
        <editor editorValue="contentEns" v-model="form.contentEn" />
      </a-form-model-item>




      <a-form-model-item label="排序" prop="sort" >
        <a-input-number v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getHelp, addHelp, updateHelp } from '@/api/help/help'
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        sort: null,

        content: null,
        contentEn: null,

        title: null,
        titleEn: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sort: [
          { required: true, message: '排序不能为空' }
        ],
        content: [
          { required: true, message: '内容不能为空' }
        ],
        contentEn: [
          { required: true, message: '英文内容不能为空' }
        ],
        titleEn: [
          { required: true, message: '英文标题不能为空' }
        ],
        title: [
          { required: true, message: '标题不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {

      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        sort: null,
        content: null,
        contentEn: null,
        title: null,
        titleEn: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      debugger
      this.reset()
      this.formType = 1
      this.open = true
      this.form = {
        id: null,
        sort: 0,
        content: null,
        title: null,
        createTime: null,
        remark: null,
      }
      this.$forceUpdate()
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHelp({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHelp(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addHelp(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
